import { DeliveryCalendarDay, DeliveryCalendarDayType } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * カタログマスタに関する機能を提供します.
 */
class DeliveryCalendarDayService {
  /**
   * 初期状態の NonBusinessDay
   */
  public get defaultNonBusinessDay(): DeliveryCalendarDay {
    return {
      guid: "",
      date: new Date(),
      type: DeliveryCalendarDayType.NonBusiness,
      createdAt: "",
      updatedAt: "",
    } as DeliveryCalendarDay;
  }
  /**
   * 初期状態の NonFleshDay
   */
  public get defaultNonFleshDay(): DeliveryCalendarDay {
    return {
      guid: "",
      date: new Date(),
      type: DeliveryCalendarDayType.NonFlesh,
      createdAt: "",
      updatedAt: "",
    } as DeliveryCalendarDay;
  }

  /**
   * 非営業日を取得して返します.
   * @returns 非営業日の配列
   */
  public async getNonBusinessDays() {
    const response = await sdk.nonBusinessDays();
    if (response?.nonBusinessDays) {
      return response.nonBusinessDays as DeliveryCalendarDay[];
    }
    return [] as DeliveryCalendarDay[];
  }

  /**
   * フレッシュお届け不可日を取得して返します.
   * @returns フレッシュお届け不可日の配列
   */
  public async getNonFleshDays() {
    const response = await sdk.nonFleshDays();
    if (response?.nonFleshDays) {
      return response.nonFleshDays as DeliveryCalendarDay[];
    }
    return [] as DeliveryCalendarDay[];
  }

  /**
   * 指定したDeliveryCalendarDayを登録します.
   * guid、createdAt、updatedAt は自動で登録されます.
   * @param calendarDay DeliveryCalendarDay
   * @returns 登録完了後のDeliveryCalendarDay
   */
  public async createDeliveryCalendarDay(input: DeliveryCalendarDay) {
    try {
      const response = await sdk.createDeliveryCalendarDay({
        createDeliveryCalendarDayInput: {
          date: input.date,
          type: input.type,
        },
      });

      if (response?.createDeliveryCalendarDay) {
        return response.createDeliveryCalendarDay;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 指定したDeliveryCalendarDayを更新します.
   * guid、createdAt、updatedAt は自動で更新されます.
   * @param calendarDay DeliveryCalendarDay
   * @returns 更新完了後のDeliveryCalendarDay
   */
  public async updateDeliveryCalendarDay(input: DeliveryCalendarDay) {
    try {
      const response = await sdk.updateDeliveryCalendarDay({
        updateDeliveryCalendarDayInput: {
          guid: input.guid,
          date: input.date,
          type: input.type,
        },
      });

      if (response?.updateDeliveryCalendarDay) {
        return response.updateDeliveryCalendarDay;
      }
    } catch (err) {
      console.log(err);
    }
  }
  public async deleteDeliveryCalendarDay(input: DeliveryCalendarDay) {
    try {
      const response = await sdk.deleteDeliveryCalendarDay({
        deleteDeliveryCalendarDayInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteDeliveryCalendarDay) {
        return response.deleteDeliveryCalendarDay;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 指定した日付を「非営業日」として登録します。また、配列にない非営業日は削除します.
   * @param days 非営業日（yyyy-mm-dd 形式の文字列）の配列
   */
  public async insertOrDeleteNonBusinessDays(days: Date[]) {
    const currentDays = await this.getNonBusinessDays();

    //削除対象を取得
    const deleted: DeliveryCalendarDay[] = [];
    currentDays.forEach(async (obj) => {
      if (!days.includes(obj.date)) {
        const res = await this.deleteDeliveryCalendarDay(obj);
        if (res) {
          deleted.push(res);
        }
      }
    });
    //追加対象を取得
    const created: DeliveryCalendarDay[] = [];
    days.forEach(async (aDay) => {
      const finded = currentDays.find((elm) => {
        return elm.date === aDay;
      });
      if (!finded) {
        const newObj = this.defaultNonBusinessDay;
        newObj.date = aDay;
        const res = await this.createDeliveryCalendarDay(newObj);
        if (res) {
          created.push(res);
        }
      }
    });
  }

  /**
   * 指定した日付を「フレッシュお届け不可日」として登録します。また、配列にないフレッシュお届け不可日は削除します.
   * @param days フレッシュお届け不可日（yyyy-mm-dd 形式の文字列）の配列
   */
  public async insertOrDeleteNonFleshDays(days: Date[]) {
    const currentDays = await this.getNonFleshDays();

    //削除対象を取得
    const deleted: DeliveryCalendarDay[] = [];
    currentDays.forEach(async (obj) => {
      if (!days.includes(obj.date)) {
        const res = await this.deleteDeliveryCalendarDay(obj);
        if (res) {
          deleted.push(res);
        }
      }
    });
    //追加対象を取得
    const created: DeliveryCalendarDay[] = [];
    days.forEach(async (aDay) => {
      const finded = currentDays.find((elm) => {
        return elm.date === aDay;
      });
      if (!finded) {
        const newObj = this.defaultNonFleshDay;
        newObj.date = aDay;
        const res = await this.createDeliveryCalendarDay(newObj);
        if (res) {
          created.push(res);
        }
      }
    });
  }
}

export default new DeliveryCalendarDayService();
