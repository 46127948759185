



















































import { DeliveryCalendarDay } from "@/graphql/client";
import { Component, Vue } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import deliveryCalendarDayService from "@/service/deliveryCalendarDayService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class CalendarEditor extends Vue {
  //---------------------------
  // data
  //---------------------------
  prevNonBusinessDays: DeliveryCalendarDay[] = [];
  nonBusinessDays: Date[] = [];
  prevNonFleshDays: DeliveryCalendarDay[] = [];
  nonFleshDays: Date[] = [];
  isProgressing = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // watch
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.isProgressing = true;
    //非営業日取得
    const days1 = await deliveryCalendarDayService.getNonBusinessDays();
    if (days1) {
      this.prevNonBusinessDays = [];
      this.nonBusinessDays = [];
      days1.forEach((elm) => {
        this.prevNonBusinessDays.push(elm);
        this.nonBusinessDays.push(elm.date);
      });
    }
    //フレッシュお届け不可日取得
    const days2 = await deliveryCalendarDayService.getNonFleshDays();
    if (days2) {
      this.prevNonFleshDays = [];
      this.nonFleshDays = [];
      days2.forEach((elm) => {
        this.prevNonFleshDays.push(elm);
        this.nonFleshDays.push(elm.date);
      });
    }
    this.isProgressing = false;
  }

  /**
   * 入力内容を保存します.
   */
  async save(): Promise<void> {
    this.isProgressing = true;

    //差分を取得
    //update
    await deliveryCalendarDayService.insertOrDeleteNonBusinessDays(
      this.nonBusinessDays
    );
    await deliveryCalendarDayService.insertOrDeleteNonFleshDays(
      this.nonFleshDays
    );
    setTimeout(() => {
      NotificationState.notifySuccess("カレンダーを更新しました！");
      this.isProgressing = false;
    }, 3000);
  }
}
