










import SettingSubPageTemplate from "@/components/templates/SettingSubPageTemplate.vue";
import CalendarEditor from "@/components/organisms/calendar/CalendarEditor.vue";

export default {
  components: {
    SettingSubPageTemplate,
    CalendarEditor,
  },
};
